import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = {
  key: 0,
  class: "icon-button-label"
}
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["disabled"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = {
  key: 0,
  class: "icon-button-label"
}
const _hoisted_8 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (!_ctx.dense)
    ? (_openBlock(), _createElementBlock("button", {
        key: 0,
        class: _normalizeClass(["ww-icon-button", { 'raised': _ctx.raised, 'primary': _ctx.primary, 'secondary': _ctx.secondary, 'disabled': _ctx.disabled, 'has-label': _ctx.label, 'loading': _ctx.waitForResponse }]),
        disabled: _ctx.disabled || _ctx.waitForResponse,
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
      }, [
        _createElementVNode("i", {
          class: "svgicon svgicon--title",
          innerHTML: _ctx.icon
        }, null, 8, _hoisted_2),
        (_ctx.label)
          ? (_openBlock(), _createElementBlock("label", _hoisted_3, _toDisplayString(_ctx.label), 1))
          : _createCommentVNode("", true),
        (_ctx.waitForResponse)
          ? (_openBlock(), _createElementBlock("i", {
              key: 1,
              class: "svgicon svgicon--loader",
              innerHTML: _ctx.loader
            }, null, 8, _hoisted_4))
          : _createCommentVNode("", true)
      ], 10, _hoisted_1))
    : (_openBlock(), _createElementBlock("button", {
        key: 1,
        class: _normalizeClass(["ww-icon-button-dense", { 'disabled': _ctx.disabled, 'has-label': _ctx.label, 'loading': _ctx.waitForResponse }]),
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args))),
        disabled: _ctx.disabled || _ctx.waitForResponse
      }, [
        _createElementVNode("i", {
          class: "svgicon svgicon--title",
          innerHTML: _ctx.icon
        }, null, 8, _hoisted_6),
        (_ctx.label)
          ? (_openBlock(), _createElementBlock("label", _hoisted_7, _toDisplayString(_ctx.label), 1))
          : _createCommentVNode("", true),
        (_ctx.waitForResponse)
          ? (_openBlock(), _createElementBlock("i", {
              key: 1,
              class: "svgicon svgicon--loader",
              innerHTML: _ctx.loader
            }, null, 8, _hoisted_8))
          : _createCommentVNode("", true)
      ], 10, _hoisted_5))
}