export default class TimeEntry {
    customer : any;
    department : any; 
    description : string; 
    editTime : number;
    employee : any;
    project : any;
    start : number;
    startDay : number; 
    startWorkstation : any;
    startstops : Array<any>;
    stop : number;
    stopWorkstation : any;
    title : string;
    sumTime: number;
    timeGone : number;
    tstamp : any;
    uid : number;
    work : any;
    workarea : any;
    
    constructor(uid: number, department: any, description : string,  title: string, start: number, stop: number, project: any, work: any, tstamp: any, employee: any, customer:any, startWorkstation: any, startstops: any, timeGone:number, editTime : number, startDay : number, sumTime : number ) {
        this.uid = uid;
        this.department = department;
        this.description = description;
        this.title = title;
        this.start = start;
        this.stop = stop;
        this.project = project;
        this.work = work;
        this.tstamp = tstamp;
        this.employee = employee;
        this.customer = customer;
        this.startWorkstation = startWorkstation;
        this.startstops = startstops;
        this.timeGone = timeGone;
        this.editTime = editTime;
        this.startDay = startDay;
        this.sumTime = sumTime; 
    }
    static createTimeEntriesFromResponse(res:any) {
        const timeentries :any = [];
        const temp = res.data.timeentries.timeentries ? res.data.timeentries.timeentries : res.data.timeentries
        temp.forEach((element: any) => {
            if (element) timeentries.push(new TimeEntry (element.uid, element.department, element.description, element.title, element.start, element.stop, element.project, element.work, element.tstamp, element.employee, element.customer, element.startWorkstation, element.startstops, element.timeGone, element.editTime, element.startDay, element.sumTime)
        )})
        return timeentries;
    }

    static createTimeEntryFromResponse(res:any) {
        //console.log('TimeEntry[createTimeEntryFromResponse] -> res', res);
        return new TimeEntry (res.uid, res.department, res.description, res.title, res.start, res.stop, res.project, res.work, res.tstamp, res.employee, res.customer, res.startWorkstation, res.startstops, res.timeGone, res.editTime, res.startDay, res.sumTime);
    }

    /**** getters & setters ****/

    getUid() {
        return this.uid;
    }

    setUid(uid:number) {
        this.uid = uid;
    }  

    getTitle() {
        return this.title;
    }

    setTitle(title:string) {
        this.title = title;
    } 

    getStart() {
        return this.start;
    }

    setStart(start:number) {
        this.start = start;
    } 

    getStop() {
        return this.stop;
    }

    setStop(stop:number) {
        this.stop = stop;
    } 

    getTimeGone() {
        return this.timeGone;
    } 

    setTimeGone(timeGone:number) {
        this.timeGone = timeGone;
    } 

    getTimeTracked() {
        let gone = 0
        for(const key in this.startstops) {
        //this.startstops.forEach((st: any) => {
            if (this.startstops[key].stop) {
                gone += (this.startstops[key].stop - this.startstops[key].start)
            } else {
                gone += ((Date.now() / 1000) - this.startstops[key].start)
            }
        }
        return gone
    }
}