
import { defineComponent } from 'vue';
import store from '@/store';

export default defineComponent({
    name: "WorkspaceFooter", 
    props:{
        activeView: {
            type: String, 
            default: ""
        },
    },
    data(){
        return{
            store
        }
    },
    watch:{
    },
    methods:{
        checkActivView(view:string){
            if(view == this.activeView){
                return true
            }
            return false
        },
        async logoutUser(){
            console.log('logout user')
        }
    }
})
