import axios from "axios"
//import { Api } from "../api"
//import { CompanySettings } from '@/model'
import AxiosErrorService from "@/service/AxiosErrorService"

const NfcChipRepository = {

    async getUpdate(){
        try{
            const res = await axios.get('http://127.0.0.1:9999')
            //console.log('getUpdate res', res?.data?.nfcChipId)
            return res?.data?.nfcChipId;
        } catch(e){
            console.log('getUpdate error:', e);
            return ''
        }
    }

}

export default NfcChipRepository;