import { UserRepository } from "../repository";
//import { DataService } from '../utils';

const UserController = {
    
    /**
    * @return {ObjectStorage} request data
    */
    async getUsers() {
        const users = await UserRepository.getUsers()
        return users
    },

    async uploadProfileImage(imageData : any) {
        const postData = new FormData()
        postData.append('image', imageData)
        postData.append('type', 'profile')
        //const res : any = await UserRepository.uploadProfileImage(postData);
        //return res
        return false
    },

    /**
    * @param {username} string
    * @return {Object} request data
    */
    async sendPasswordLink(username: string) {
       const postData = `username=${username}`
       return await UserRepository.forgotPassword(postData);
    },

    /**
    * @param {postData} any
    * @return {Object} request data
    */
    async resetPassword(postData: any){
        //postData = DataService.buildPostData(postData,'', true);
        postData = JSON.stringify(postData)
        const response : any = await UserRepository.resetPassword(postData)
        return response
    },
    
}
export default UserController;

