//import { DataService } from '../utils';
import { PresenceRepository } from '@/repository/index.ts';

const PresenceController = {
    async getPresences(start: number, end: number, employeeId: number) {
        const postData = `start=${start}&end=${end}&employee=${employeeId}`
        const res : any = await PresenceRepository.getPresences(postData)
        return res
    },
    async getPresence(uid:any){
        const res : any = await PresenceRepository.getPresence(uid)
        return res
    },
    async getActivePresence(employeeId:any){
        const res : any = await PresenceRepository.getActivePresence(employeeId)
        return res
    },
    async createPresence(employee:any){
        const postData = `employeeId=${employee.getUid()}`
        const res : any = await PresenceRepository.createPresence(postData)
        return res
    },
    async endPresence(employee:any){
        const postData = `employeeId=${employee.getUid()}`
        const res : any = await PresenceRepository.endPresence(postData)
        return res
    },
    async updatePresence(presenceId: number, employeeId: number, come: number, go: number, comeWorkstationId: number, goWorkstationId: number) {
        const postData = `presenceId=${presenceId}&employeeId=${employeeId}&come=${come}&go=${go}&comeWorkstationId=${comeWorkstationId}&goWorkstationId=${goWorkstationId}`
        const res : any = await PresenceRepository.updatePresence(postData)
        return res
    },
    async deletePresence(presenceId: number){
        const postData = `presenceId=${presenceId}`
        const res : any = await PresenceRepository.deletePresence(postData)
        return res
    }
 }
export default PresenceController;

