import { WorkbreakRepository } from "../repository";
//import { DataService } from '../utils';
//import { Break } from "@/model/index.ts";

const WorkbreakController = {

    async getWorkbreak(uid:any){
        const res : any = await WorkbreakRepository.getWorkbreak(uid)
        return res
    },
    async getActiveWorkbreak(employeeId:any){
        const res : any = await WorkbreakRepository.getActiveWorkbreak(employeeId)
        return res
    },
    async getWorkbreaks(start: number, end: number, employeeId: number) {
        const postData = `start=${start}&end=${end}&employee=${employeeId}`
        const res : any = await WorkbreakRepository.getWorkbreaks(postData);
        return res
    },        
    async getDayWorkbreaks(employeeId:number, day:number){
        const postData = `employeeId=${employeeId}&day=${day}`
        const res : any = await WorkbreakRepository.getDayWorkbreaks(postData);
        return res
    },    
    async createWorkbreak(employee:any){
        const postData = `employeeId=${employee.getUid()}`
        const res : any = await WorkbreakRepository.createWorkbreak(postData)
        return res
    },
    async stopWorkbreak(employee:any){
        const postData = `employeeId=${employee.getUid()}`
        const res : any = await WorkbreakRepository.stopWorkbreak(postData)
        return res
    },
    async deleteWorkbreak(workbreakId: number){
        const postData = `workbreakId=${workbreakId}`
        const res : any = await WorkbreakRepository.deleteWorkbreak(postData)
        return res
    },
    async updateWorkbreak(workbreakId: number, employeeId: number, start: number, stop: number, startWorkstationId: number, stopWorkstationId: number) {
        const postData = `workbreakId=${workbreakId}&employeeId=${employeeId}&start=${start}&stop=${stop}&startWorkstationId=${startWorkstationId}&stopWorkstationId=${stopWorkstationId}`
        const res : any = await WorkbreakRepository.updateWorkbreak(postData)
        return res
    },    
}
export default WorkbreakController;

