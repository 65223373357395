export default class Workstation {
    uid: number;
    title: string;
    department : number;
    unit : number;
    workarea: number
    uuidWorkstation: string
    nfcChipReader: boolean
    presenceAllowed: boolean
    workbreakAllowed: boolean
    timeentryAllowed: boolean
  
    constructor(uid: number, title: string, department : number, unit : number, workarea: number, 
        uuidWorkstation: string, nfcChipReader: boolean, presenceAllowed: boolean, workbreakAllowed: boolean, timeentryAllowed: boolean) {
        this.uid = uid;
        this.title = title;
        this.department = department
        this.unit = unit
        this.workarea = workarea
        this.uuidWorkstation = uuidWorkstation
        this.nfcChipReader = nfcChipReader
        this.presenceAllowed = presenceAllowed
        this.workbreakAllowed = workbreakAllowed
        this.timeentryAllowed = timeentryAllowed
    }

    
    static createWorkstationsFromResponse(res:any) {
        const workstations :any = [];
        res.data.workstations.forEach((element: any) => {
            workstations.push(new Workstation(element.uid, element.title, element.department?.uid || element.department, element.unit?.uid || element.unit, element.workarea?.uid || element.workarea, element.uuidWorkstation, element.nfcChipReader, element.presenceAllowed, element.workbreakAllowed, element.timeentryAllowed)
        )})
        return workstations;
    }

    static createWorkstationFromResponse(res:any) {
        //console.log('Workstation[createWorkstationFromResponse] -> res', res);
        return new Workstation (res.uid, res.title, res.department?.uid || res.department, res.unit?.uid || res.unit, res.workarea?.uid || res.workarea, res.uuidWorkstation, res.nfcChipReader, res.presenceAllowed, res.workbreakAllowed, res.timeentryAllowed);
    }
    
    /**** getters & setters ****/

    getUid() {
        return this.uid;
    }

    setUid(uid:number) {
        this.uid = uid;
    }  

    getTitle() {
    return this.title;
    }

    setTitle(title:string) {
        this.title = title;
    }

    getDepartment() {
        return this.department;
    }

    setDepartment(department:number) {
        this.department = department;
    }

    getUnit() {
        return this.unit;
    }

    setUnit(unit:number) {
        this.unit = unit;
    }

    getWorkarea() {
        return this.workarea;
    }

    setWorkarea(workarea:number) {
        this.workarea = workarea;
    }

    getUuidWorkstation() {
        return this.uuidWorkstation;
    }

    setUuidWorkstation(uuidWorkstation:string) {
        this.uuidWorkstation = uuidWorkstation;
    }    

    getNfcChipReader() {
        return this.nfcChipReader;
    }

    setNfcChipReader(nfcChipReader:boolean) {
        this.nfcChipReader = nfcChipReader;
    }        

    getPresenceAllowed() {
        return this.presenceAllowed;
    }

    setPresenceAllowed(presenceAllowed:boolean) {
        this.presenceAllowed = presenceAllowed;
    }     

    getWorkbreakAllowed() {
        return this.workbreakAllowed;
    }

    setWorkbreakAllowed(workbreakAllowed:boolean) {
        this.workbreakAllowed = workbreakAllowed;
    }     

    getTimeentryAllowed() {
        return this.timeentryAllowed;
    }

    setTimeentryAllowed(timeentryAllowed:boolean) {
        this.timeentryAllowed = timeentryAllowed;
    }     

}
