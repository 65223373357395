export default class CompanySettings {
    uid: number
    defaultProject: number
    workspaceUser: number
    
    constructor(uid: number, defaultProject: number, workspaceUser: number) {
        this.uid = uid
        this.defaultProject = defaultProject
        this.workspaceUser = workspaceUser
    }

    static createCompanySettingsFromResponse(res:any) {
        return new CompanySettings(res.uid, (res.defaultProject.uid ? res.defaultProject.uid : res.defaultProject), (res.workspaceUser?.uid ? res.workspaceUser.uid : res.workspaceUser))
    }


    /**** getters & setters ****/
    getUid() {
        return this.uid
    }

    setUid(uid:number) {
        this.uid = uid
    }  

    getDefaultProject() {
        return this.defaultProject
    }

    setDefaultProject(defaultProject:number) {
        this.defaultProject = defaultProject
    }  

    getWorkspaceUser() {
        return this.workspaceUser
    }

    setWorkspaceUser(workspaceUser:number) {
        this.workspaceUser = workspaceUser
    }  

}