
import { defineComponent, onMounted, ref, computed, watch} from 'vue';
import { Unique } from '@/utils';

export default defineComponent({
name: "SelectSingle",
props: {
    options: {
      type: Array,
      default: null
    },
    value: {
      type: Number,
      default: 0
    },
    disabled: {
      type: Boolean,
      default: false
    },
    primary: {
      type: Boolean,
      default: false
    },
    placeholder:{
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    displayProperty: {
      type: String,
      default: ''
    },
    valueProperty: {
      type: String,
      default: ''
    },
    defaultPrependOption: {
      type: String,
      default: null
    }
},
data() {
  return {
    icons: {
      chevrondown: '<svg width="18" height="11" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg">  <path d="M0.799988 1.31L8.79999 9.31L16.8 1.31" stroke="#1B1B1B" stroke-miterlimit="10" stroke-linecap="square"/></svg>'
    }
  }
},
/* watch: {
  options(val) {
    this.setSelectOptions()
    this.modelValue = this.props.value
    this.selectedText = this.setSelectTextById(this.props.value)
  }
}, */
/* watch: {
  storedValue(val) {
    this.value = val.value;
  }
}, */
setup(props, { emit }) {
  const domSelected = ref<HTMLParagraphElement|null>(null) 
  const modelValue = ref(0)
  const listActive = ref(false)
  const selectedText = ref('')
  const cId = Unique.getUnique()
  const selectOptions = ref([] as any)

  const bodyClickHandler = ref()

  const selectListStyle = computed(() => {
    let style = "";
    if (domSelected.value) {
      const br = domSelected?.value?.getBoundingClientRect();
      style = listActive.value ? "top: " + Number(br.height) +  "px; width: " + (br.width) + "px;" : ""
    }
    return style;
  })
  
  onMounted(() => {
    setSelectOptions()
    modelValue.value = props.value
    selectedText.value = setSelectTextById(props.value)
  })

  const setSelectOptions = () => {
    if (props.defaultPrependOption) {
      const first = {} as any
      first[props?.valueProperty || 'uid'] = 0
      first[props?.displayProperty || 'title'] = props.defaultPrependOption
      selectOptions.value = [...[first], ...props.options]
      if (modelValue.value == 0) {
        selectedText.value = setSelectTextById(props.value);
      }
    } else {
      selectOptions.value = props.options
    }
  }

  bodyClickHandler.value = (ev: Event) => {
      const composed = ev.composedPath();
      if (composed) {
        const close = composed.filter((el: any) => el.classList && el.classList.contains('selected') && el?.id == 'selectSingleSelect'+cId);
      
        if (!close.length) {
          listActive.value = false;
        }
      }
  }

  const handleSelectClick = () => {
    listActive.value = !listActive.value
    document.body.addEventListener("click", bodyClickHandler.value)
  }

  const setSelectTextById = (id: number): string => {
    const findProp = props?.valueProperty || 'uid'
    const found : any = selectOptions.value ? selectOptions.value.find((o: any) => o[findProp] == id) : null
    const showProp : string = props?.displayProperty || 'title';
    return (found ? found?.[showProp] : '')
  }

  const handleSelectListClick = (ev : Event) => {
    const target = ev?.target as HTMLElement;
    if (target.nodeName == "LI") {
      //console.log('handleSelectListClick', target.dataset['value'], target.innerHTML.trim());
      /* selectedText.value = Number(target.dataset['value'])*/
      modelValue.value = Number(target.dataset['value'])
      selectedText.value = target.innerHTML.trim()
      listActive.value = false
      emit("onChange", modelValue.value, selectedText.value)
    }
  }

  watch(() => props.value, (oldValue, newValue) => {
    //console.log('watch props.storedValue, oldValue, newValue', props.value, oldValue, newValue)
    modelValue.value = props.value;
    selectedText.value = setSelectTextById(props.value);
  })

  watch(() => listActive.value, (newValue) => {
    if (!newValue) document.body.removeEventListener('click', bodyClickHandler.value)
  })

  watch(() => props.options, (newValue) => {
    //console.log('watch props.options', props.options)
    setSelectOptions()
    modelValue.value = props.value
    selectedText.value = setSelectTextById(props.value)
  })

  return {
    props,
    domSelected,
    modelValue,
    cId,
    listActive,
    selectedText,
    selectListStyle,
    selectOptions,
    setSelectTextById,
    setSelectOptions,
    handleSelectClick,
    handleSelectListClick
  }
},
methods: {
  /* handleSelectClick() {
    this.listActive = !this.listActive;
  }, */
  /* handleSelectListClick(ev : any) {
      
      if (ev.target.nodeName == "LI") {
          this.selectedValue.value = this.value = ev.target.dataset['value'];
          this.selectedValue.text = ev.target.innerHTML.trim();
          this.listActive = false;
          this.$emit("onChange", ev, this.item, this.value);
      }
  } */
},
})
