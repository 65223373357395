//import { DataService } from '../utils';
import { EmployeeRepository } from '@/repository/index.ts';

const EmployeeController = {

    async fetchEmployees() {
        const res : any = await EmployeeRepository.fetchEmployees();
        return res;
    },
    async getEmployeeById(uid:any) {
        const res : any = await EmployeeRepository.getEmployeeById(uid);
        return res;
    },

    async getEmployeeByChip(chip: string){
        const res : any = await EmployeeRepository.getEmployeeByChip(chip)
        return res
    },

    async getEmployeeTimeTables(uid:any) {
        //console.error("WARNING: static data from EmployeeController[getEmployeeTimeTables]");
        const timeTables = [
            { uid: 1, date: "Jänner 2023", file: "link" },
            { uid: 2, date: "Dezember 2022", file: "link" }
        ]
        return timeTables;
    },
    /* async fetchEmployeeGroups() {
        const res : any = await EmployeeRepository.fetchEmployeeGroups();
        return res;
    }, */
    async createEmployee(formData : any, image:any) {
        //const formDataString = `firstName=${formData.firstName}&lastName=${formData.lastName}&siNumber=${formData.siNumber}&number=${formData.number}&dob=${formData.dateOfBirth}`
        const postData = new FormData();
        postData.append('image', image)
        postData.append('type', 'profile')
        Object.keys(formData).forEach(key => {
            if (formData[key]) {
                postData.append(key, formData[key]);
            }
        });
        const res : any = await EmployeeRepository.createEmployee(postData)
        return res;
    },

    async updateEmployee(formData : any) {
        const postData = new FormData()
        Object.keys(formData).forEach(key => {
                //allow empty values
            //if (formData[key]) {
                postData.append(key, formData[key]);
            //}
        });
        //const postData = `uid=${formData.uid}&firstName=${formData.firstName}&lastName=${formData.lastName}&siNumber=${formData.siNumber}&number=${formData.number}`
        const res : any = await EmployeeRepository.updateEmployee(postData);
        return res;
    },

    async updateChipId(employee: number, chipId: string) {
        const postData = `employee=${employee}&nfcChipId=${chipId}`
        const res : any = await EmployeeRepository.updateChipId(postData);
        return res;
    },

    async addProject(employee: number, project: number) {
        const postData = `employee=${employee}&project=${project}`
        const res : any = await EmployeeRepository.addProject(postData);
        return res;
    },

    async removeProject(employee: number, project: number) {
        const postData = `employee=${employee}&project=${project}`
        const res : any = await EmployeeRepository.removeProject(postData);
        return res;
    },

    async deleteEmployee(employeeId : number) {
        const postData = `uid=${employeeId}`;
        const res : any = await EmployeeRepository.deleteEmployee(postData);
        return res
    }
}
export default EmployeeController;
