import axios from "axios";
import { Api } from "../api";
import { ObjectStorage, Workstation } from "@/model/index";
import AxiosErrorService from "@/service/AxiosErrorService";

export default class WorkstationRepository {
    static workstations = new ObjectStorage([]); 

    static async fetchWorkstations() {
        let result = null;
        await axios.get(Api.BACKEND_URL + Api.GET_WORKSTATIONS_ACTION)
        .then((response:any) => {
         console.log('fetchWorkstations res', response)
            this.workstations = new ObjectStorage(Workstation.createWorkstationsFromResponse(response));
            result = this.workstations;
        }).catch((error) => {
            result = AxiosErrorService.handleError(error).error.data;
        });
        return result;
    }

    static async getWorkstationById(uid : any) {
        let result = null;
        await axios.get(Api.BACKEND_URL + Api.GET_WORKSTATION_BY_ID_ACTION + uid)
        .then((response:any) => {
            result = {
                workstation: Workstation.createWorkstationFromResponse(response.data.workstation)
            }
        }).catch((error) => {
            result = AxiosErrorService.handleError(error).error.data;
        });
        return result;  
    }

    static async getWorkstationByUuid(postData: any) {
        let result = null;
        await axios.post(Api.BACKEND_URL + Api.GET_WORKSTATION_BY_UUID_ACTION, postData)
        .then((response:any) => {
            result = {
                workstation: Workstation.createWorkstationFromResponse(response.data.workstation)
            }
        }).catch((error) => {
            result = AxiosErrorService.handleError(error).error.data;
        });
        return result;  
    }

    static async createWorkstation(postData : any) {
        let result = null;
        await axios.create().post(Api.BACKEND_URL + Api.CREATE_WORKSTATION_ACTION, postData)
        .then((response:any) => {
            console.log("createWorkstation res", response)
            result = {
                workstation: Workstation.createWorkstationFromResponse(response.data.workstation)
            }
        }).catch((error) => {
            result = AxiosErrorService.handleError(error).error.data;
        });
        return result;
    }

    static async updateWorkstation(postData : any) {
        let result = null;
        await axios.create().post(Api.BACKEND_URL + Api.UPDATE_WORKSTATION_ACTION, postData)
        .then((response:any) => {
            console.log("createWorkstation res", response)
            result = {
                workstation: Workstation.createWorkstationFromResponse(response.data.workstation)
            }
        }).catch((error) => {
            result = AxiosErrorService.handleError(error).error.data;
        });
        return result;
    }

    static async deleteWorkstation(postData:any) {
        let result = null;
        await axios.create().post(Api.BACKEND_URL + Api.DELETE_WORKSTATION_ACTION, postData)
        .then((response:any) => {
            console.log("deleteWorkstation res", response)
            result = {
                workstation: Workstation.createWorkstationFromResponse(response.data.workstation)
            }
        }).catch((error) => {
            result = AxiosErrorService.handleError(error).error.data;
        });
        return result
    }  
}
