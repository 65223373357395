
import { defineComponent } from 'vue'
import { IconButton, Button } from '@/ui/index'
import { RightController } from '@/controller'
import store from '@/store/index'
import { User, Employee } from "@/model/index.ts";
import { DateTimeUtils } from '@/utils'

export default defineComponent({
    name: "WorkspaceHeader", 
    components: { IconButton },
    props: {
        sessionUser: {
            type: User,
            default: null
        },
        sessionEmployee: {
            type: Employee,
            default: null
        }
    },
    data() {
        return {
            store,
            navActive: false,
            activeDay: 0,
            today: 0,
            icons: {
                'settings': '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" version="1.1" viewBox="0 0 16 18">  <g>        <circle cx="8" cy="9" r="2.2" fill="none" stroke="#1b1b1b" stroke-linecap="square" stroke-miterlimit="10"/>        <path d="M8,1h-1.5l-.3,2.4c-.8.3-1.6.7-2.2,1.3,0,0-2.3-1-2.3-1L.3,6.3l2,1.5c0,.4-.1.8-.1,1.3s0,.9.1,1.3c0,0-2,1.5-2,1.5l1.5,2.5s2.3-1,2.3-1c.6.6,1.4,1,2.2,1.3l.3,2.4h1.5" fill="none" stroke="#1b1b1b" stroke-linecap="square" stroke-miterlimit="10"/>        <path d="M8,17h1.5l.3-2.4c.8-.3,1.6-.7,2.2-1.3,0,0,2.3,1,2.3,1l1.5-2.5-2-1.5c0-.4.1-.8.1-1.3s0-.9-.1-1.3c0,0,2-1.5,2-1.5l-1.5-2.5s-2.3,1-2.3,1c-.6-.6-1.4-1-2.2-1.3l-.3-2.4h-1.5" fill="none" stroke="#1b1b1b" stroke-linecap="square" stroke-miterlimit="10"/>  </g></svg>'
            },
            currentTime: 0
        }
    },
    computed:{
        displayActiveDay() : string {
            return DateTimeUtils.convertTimeStampToDate(this.activeDay, true)
        },
        displayCurrentTime() : string {
            return DateTimeUtils.convertTimeStampToTime(this.currentTime)
        },
        readOnly() : boolean {
                //need to cast this - otherwise we get an error
                //Property 'activeDay' does not exist on type 'ComponentPublicInstance<{}, {}, {}, {}, {}, {}, {}, {}, false, ComponentOptionsBase<{}, {}, {}, {}, {}, ComponentOptionsMixin, ComponentOptionsMixin, {}, string, {}, {}, string>, {}>
            const activeDay = (this.activeDay as number)
            const today = (this.today as number)
            return today != activeDay
        }
    },
    created() {
        this.activeDay = DateTimeUtils.getDayNoonTimestamp(new Date())
        this.today = this.activeDay
        this.setCurrentTime()
        console.log('this.sessionUser', this.sessionEmployee)
    },
    methods:{
            //handlers
        onSettingsClick() {
            console.log('onSettings');
            //if (this.sessionUser.uid && this.sessionEmployee && this.sessionEmployee?.group?.isAdmin) {
                this.$router.push('/workspace/setchip')
            //}
        },
            //functions
        setCurrentTime() {
            setTimeout( () => { 
                DateTimeUtils.getDayNoonTimestamp(new Date())
                this.setCurrentTime() 
            }, 1000 );
            this.currentTime = new Date().getTime() / 1000;
        },
    }
})
