import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = ["id"]
const _hoisted_3 = ["placeholder"]
const _hoisted_4 = {
  key: 0,
  class: "sub-header"
}
const _hoisted_5 = ["data-value"]
const _hoisted_6 = { class: "li-empty-state" }
const _hoisted_7 = { class: "empty-state-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconButton = _resolveComponent("IconButton")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["ww-selectsingle-search", _ctx.componentClass]),
    id: _ctx.cId
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["selectsingle-list", { 'is-disabled': _ctx.disabled, 'primary': _ctx.primary}]),
      ref: "domCustomSelect"
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["input-action-bar", { 'list-is-active': _ctx.listActive }]),
        id: 'selectSingleSelectList' + _ctx.cId
      }, [
        _withDirectives(_createElementVNode("input", {
          type: "text",
          placeholder: _ctx.placeholder,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
          onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleChangeInput && _ctx.handleChangeInput(...args))),
          onKeydown: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleInputKeyDown && _ctx.handleInputKeyDown(...args)))
        }, null, 40, _hoisted_3), [
          [_vModelText, _ctx.inputValue]
        ]),
        (_ctx.undoInputValue != 0 && _ctx.undoInputValue != _ctx.value.value)
          ? (_openBlock(), _createBlock(_component_IconButton, {
              key: 0,
              icon: _ctx.icons.undo,
              onOnClick: _ctx.handleUndoChanges
            }, null, 8, ["icon", "onOnClick"]))
          : _createCommentVNode("", true),
        _createVNode(_component_IconButton, {
          icon: _ctx.icons.chevrondown,
          onOnClick: _ctx.handleToggleList,
          class: _normalizeClass({ 'rotate-180': _ctx.listActive })
        }, null, 8, ["icon", "onOnClick", "class"]),
        (_ctx.allowCreateNew)
          ? (_openBlock(), _createBlock(_component_IconButton, {
              key: 1,
              icon: _ctx.store.getters.svgIcons.add,
              disabled: !_ctx.enableAddButton,
              class: _normalizeClass({'lightUp': _ctx.enableAddButton }),
              onOnClick: _ctx.handleAddItemClicked
            }, null, 8, ["icon", "disabled", "class", "onOnClick"]))
          : _createCommentVNode("", true)
      ], 10, _hoisted_2),
      (_ctx.listActive && _ctx.filteredOptions.length > 0)
        ? (_openBlock(), _createElementBlock("ul", {
            key: 0,
            class: _normalizeClass(["selectlist", { 'is-active': _ctx.listActive }]),
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleSelectListClick && _ctx.handleSelectListClick(...args))),
            style: _normalizeStyle(_ctx.selectListStyle),
            ref: "domSelectList"
          }, [
            (_ctx.subheader )
              ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.subheader.text), 1))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredOptions, (option, index) => {
              return (_openBlock(), _createElementBlock("li", {
                key: 'optionItem' + _ctx.item.name + index,
                "data-value": option.uid,
                class: _normalizeClass({'isSelected': _ctx.isSelectedItem(option.uid)})
              }, _toDisplayString(option[_ctx.labelProperty] ? option[_ctx.labelProperty] : option.text), 11, _hoisted_5))
            }), 128))
          ], 6))
        : (_openBlock(), _createElementBlock("ul", {
            key: 1,
            class: _normalizeClass(["selectlist", { 'is-active': _ctx.listActive }]),
            onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.handleHideList && _ctx.handleHideList(...args))),
            style: _normalizeStyle(_ctx.selectListStyle)
          }, [
            _createElementVNode("li", _hoisted_6, [
              _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.emptyStateText ? _ctx.emptyStateText : _ctx.$t('placeholder.noDataFound')), 1),
              (_ctx.allowCreateNew)
                ? (_openBlock(), _createBlock(_component_Button, {
                    key: 0,
                    text: _ctx.$t('button.create'),
                    dense: true,
                    onOnClick: _ctx.handleAddItemClicked
                  }, null, 8, ["text", "onOnClick"]))
                : _createCommentVNode("", true)
            ])
          ], 6))
    ], 2)
  ], 10, _hoisted_1))
}