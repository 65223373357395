import router from "@/router";
import { SessionRepository } from '@/repository/index.ts';
import { User, Employee, Company } from "@/model/index.ts";
import { useEventBus } from '@vueuse/core'
import { appEventKey } from '@/bus/eventkey'

const SessionController = {
    router,
    loginRequired: true,
    sessionUser: {} as User,
    sessionEmployee: new Employee(0, '', '', '', '', '', [], null, '', 0, '', '', 0, null, null, null, null, []),
    sessionCompany: new Company(0, '', 0, null),
    eventBus: useEventBus(appEventKey),

    async getSessionUser() {
        const res : any = await SessionRepository.getSessionUser()
        //console.log('SessionController getSessionUser', res, res?.user)
        if(res?.user) {        
            console.log('res', res)
            this.sessionUser = await User.createUserFromResponse(res.user)
            if (res.employee) this.sessionEmployee =  await Employee.createEmployeeFromResponse(res.employee)
            this.eventBus.emit('onUser')

            const result = {
                'user': this.sessionUser,
                'employee': this.sessionEmployee
            }
            return result
        } else {
            this.router.push('/login')
        }
    },

    async getSessionCompany() {
        const res : any = await SessionRepository.getSessionCompany();
        if (res.company) {
            this.sessionCompany = await Company.createCompanyFromResponse(res.company)
        }
        return this.sessionCompany
    },

    setSessionEmployee(employee: Employee) {
        this.sessionEmployee = employee
    },

    destroySession() {
        this.sessionUser = {} as User
        this.sessionEmployee = new Employee(0, '', '', '', '', '', [], null, '', 0, '', '', 0, null, null, null, null, [])
        this.sessionCompany = new Company(0, '', 0, null)
    }
 }
export default SessionController;

